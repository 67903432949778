

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}
.ItemNameNewArrivalPackageDetCSS {
    margin-bottom: 0; /* Remove bottom margin */
    padding-bottom: 0; /* Remove bottom padding */

    margin-left: 10px; /* Adjust the pixel value for your desired push */
    font-family: "bebas_neueregular1", sans-serif;
    font-size: 2.4em;
    font-weight: 400;
    font-style: normal;
    transform: skewX(-5deg); /* Makes the text slightly italic */
    -webkit-text-stroke: 0.7px; /* Adjust the stroke width as needed */
}
.progress-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
    z-index: 1000; /* Ensure it appears on top of other content */
}
.MenuAppbarPC{
    color:red;
}

.progress-container.hidden {
    display: none;
}

    .progress-container img {
        width: 150px; /* Adjust the size as needed */
        height: 150px;
    }

.PriceCard { 
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
}
.ColorPrice {
    color: rgb(90,151,145);
    background-color: rgb(90,151,145);
}


@keyframes vibrate {
    0% {
        transform: translate(0);
    }

    20% {
        transform: translate(-1px, 1px);
    }

    40% {
        transform: translate(1px, -1px);
    }

    60% {
        transform: translate(1px, 1px);
    }

    80% {
        transform: translate(1px, -1px);
    }

    100% {
        transform: translate(0);
    }
}

.vibratingIcon {
    animation: vibrate 0.2s linear infinite;
}


.AddCardBtn {
    color: rgb(90,151,145) !important;
}
.AddedCheckCircleCardBtn {
    color: rgb(90,151,145) !important;
}
.PackageDetPrice {
    color: rgb(90,151,145);
    margin-left: 10px; /* Adjust the pixel value for your desired push */
    font-family: "bebas_neueregular1", sans-serif;
    font-size: 2.4em;
    font-weight: 400;
    font-style: normal;
    transform: skewX(-5deg); /* Makes the text slightly italic */
    -webkit-text-stroke: 0.7px; /* Adjust the stroke width as needed */
}
.SizeDiv {
    border-bottom: 1px solid #ccc; /* Border bottom style */
    display: flex;
}
.AddToCartPackageDetailsQty{
    height:40px;

}

.AddToCartPackageDetailsBtn {
    position: relative;
    overflow: hidden;
    min-width: 150px; /* Adjust as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    text-transform: capitalize !important;
    color: black !important;
    background-color: transparent !important;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
} 

    .AddToCartPackageDetailsBtn:hover {
        background-color: #f0f0f0; /* Light background color on hover */
    }

    .AddToCartPackageDetailsBtn:active {
        min-width: 150px; /* Adjust as needed */
        transform: scale(0.98); /* Slight scale down effect on click */
    }
    .AddToCartPackageDetailsBtn.loading {
        min-width: 150px; /* Adjust as needed */
        pointer-events: none;
        color: transparent;
        transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
    }

    .AddToCartPackageDetailsBtn .MuiCircularProgress-root {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .AddToCartPackageDetailsBtn.success {
        min-width: 150px; /* Adjust as needed */
        background-color: #4caf50;
        color: white;
        transition: background-color 0.5s ease, color 0.5s ease;
    }

    .AddToCartPackageDetailsBtn.idle .MuiSvgIcon-root {
        animation: checkmark 0.5s ease forwards;
    }
        .AddToCartPackageDetailsBtn.success .MuiSvgIcon-root {
            animation: checkmark 0.5s ease forwards;
        }

        /* Custom style for slick dots */
.slick-dots li button:before {
    font-size: 10px; /* Size of the dot */
    color: white !important  /* Color of the dot */
}

.slick-dots li.slick-active button:before {
    color: black !important; /* Color of the active dot */
}

.slick-dots {
    bottom: 10px; /* Adjust position if needed */
}

.slick-dots li {
    margin: 0 5px; /* Space between dots */
}

/* Container to ensure full responsiveness */
.image-slider-container {
    width: 100%; /* Full-width container */
    max-width: 1200px; /* Max width for large screens */
    margin: 0 auto; /* Center the slider */
    overflow: hidden;
    position: relative;
}

/* Ensure the image fits within its container and retains aspect ratio */
.slider-image {
    width: 100%; /* Image takes full width */
    height: auto; /* Adjust height automatically */
    object-fit: cover; /* Cover the container while maintaining aspect ratio */
    transition: transform 0.3s ease-in-out;
    cursor: pointer; /* Indicate image is clickable */
}

    /* Slight zoom effect on image hover */
    .slider-image:hover {
        transform: scale(1.05);
    }

/* Styling for the next and previous buttons */
.slick-prev, .slick-next {
    z-index: 1;
    font-size: 30px;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    border-radius: 50%;
    padding: 10px;
    transition: background-color 0.3s ease-in-out;
    width: 50px;
    height: 50px;
    display: none; /* Initially hidden */
}

    /* On hover, make the buttons more visible */
    .slick-prev:hover, .slick-next:hover {
        background-color: rgba(255, 255, 255, 0.7);
    }

/* Adjust position of the navigation arrows */
.slick-prev {
    left: 10px; /* Move left arrow */
}

.slick-next {
    right: 10px; /* Move right arrow */
}

/* Dots customization */
.slick-dots li button:before {
    color: white;
}

.slick-dots li.slick-active button:before {
    color: yellow;
}

/* Media query for small screens (below 1024px) */
@media (max-width: 1023px) {
    .image-slider-container {
        height: auto; /* Auto-adjust height based on content */
        max-height:500px;
        width: 100%; /* Full width */
    }

    .slider-image {
        height: 400px; /* Dynamic height */
        object-fit: cover; /* Ensure image scales properly */
    }

    .slick-prev, .slick-next {
        display: none !important; /* Hide buttons on large screens */
    }
}

/* Media query for large screens (1024px and above) */
@media (min-width: 1024px) {
    .image-slider-container {
        height: 400px; /* Fixed height on large screens */
        width: 100%; /* Full width */
    }

    .slider-image {
        height: 400px; /* Fixed height for images */
        object-fit: cover; /* Ensure image scales properly */
    }

    /* Hide the next/previous buttons on large screens */
 
}



    @keyframes checkmark {
        0% {
            transform: scale(0);
        }

        100% {
            transform: scale(1);
        }
    }

    .ToolsProductDetails {
        max-width: 500px;
    }

    .NewArrivalGenericTitleCSS {
        margin-left: 10px; /* Adjust the pixel value for your desired push */
        font-family: "bebas_neueregular1", sans-serif;
        font-size: 2.4em;
        font-weight: 400;
        -webkit-text-stroke: 0.5px; /* Adjust the stroke width as needed */
    }

    .ProductListTitle {
        margin: 0;
        text-align: center;
        margin-left: 10px; /* Adjust the pixel value for your desired push */
        font-family: "bebas_neueregular1", sans-serif;
        font-size: 2.8em;
        font-weight: 400;
        font-style: normal;
        transform: skewX(-5deg); /* Makes the text slightly italic */
        -webkit-text-stroke: 0.5px; /* Adjust the stroke width as needed */
    }

    .BrandNamePackageDetails {
        margin-left: 10px !important /* Adjust the pixel value for your desired push */
    }

    .NewArrivalGenericBodyCSS {
    }

    .ItemNameNewArrivalCSS {
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
    }

    .breadcrumb {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
    }

    .breadcrumbs__arrow {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        margin: 0 10px;
        opacity: .4;
    }

    .breadcrumb-link {
        font-size: small;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
        text-decoration: none;
        color: black !important;
    }

        .breadcrumb-link:hover {
            text-decoration: none; /* Remove underline on hover */
        }

    .linkItemNameProductPd {
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
        font-size: small;
    }

    .breadcrumb-current {
        color: red;
        font-weight: bold;
    }


    .image-zoom-container {
        position: relative;
        /* other styles as before */
    }

    .HomeLink {
        font-size: small;
    }

    .image-thumbnail {
        cursor: pointer;
        /* styles for thumbnail image */
    }

    .RootNewArrivalCSS {
        margin-left: 10px; /* Adjust the pixel value for your desired push */
        /*font-family: "bebas_neueregular1", sans-serif;
    font-size: 2.4em;
    font-weight: 400;
    font-style: normal; 
    -webkit-text-stroke: 0.0px;*/ /* Adjust the stroke width as needed */
    }

    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7); /* semi-transparent black */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal-content {
        background-color: white;
        padding: 20px;
        max-width: 90%;
        max-height: 90%;
        overflow: auto;
        position: relative;
        text-align: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 24px;
        cursor: pointer;
    }

        .close-button:hover {
            color: #888;
        }

    .modal-content img {
        width: 100%;
        height: auto;
        display: block;
    }

    .image-description {
        margin-top: 20px;
        text-align: center;
    }


    @media (prefers-reduced-motion: no-preference) {
        .App-logo {
            animation: App-logo-spin infinite 20s linear;
        }
    }


    @keyframes slideIn {
        from {
            transform: translateX(-100%);
            opacity: 0;
        }

        to {
            transform: translateX(0);
            opacity: 1;
        }
    }

    .list-item-transition {
        transition: transform 0.5s ease, background-color 0.3s ease; /* Default transition duration */
        animation: slideIn 0.6s ease forwards; /* Animation with duration */
    }

    .Btn {
        transition: transform 0.5s ease, background-color 0.3s ease; /* Default transition duration */
        animation: slideIn 0.6s ease forwards; /* Animation with duration */
    }



    .App-header {
        background-color: #282c34;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: calc(10px + 2vmin);
        color: white;
    }

    .remaining-days-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }

    .remaining-days {
        background: linear-gradient(145deg, #e0e0e0, #f8f8f8);
        border-radius: 20px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        padding: 20px;
        display: flex;
        align-items: center;
        max-width: 600px; /* Set a maximum width to prevent the card from becoming too wide on larger screens */
        width: 99%; /* Ensure the card takes up the full width of its container */
    }

    @font-face {
        font-family: 'bebas_neueregular1'; /* Replace with 'Bebas Neue' for clarity */
        src: url('./fonts/bebasneue-regular-webfont.woff2') format('woff2'), url('./fonts/bebasneue-regular-webfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }


    .duration-icon {
        margin-right: 10px;
        color: darkgray;
    }



    .remaining-days-label {
        font-size: 24px;
        font-weight: bold;
        margin-right: 10px;
    }

    .remaining-days-label-red {
        color: red;
    }

    .remaining-days-label-green {
        color: green;
    }

    .days-count {
        font-size: 20px;
        color: #888;
        display: block;
        animation: fadeInUp 0.5s ease;
    }

    .days-count-red {
        color: red;
    }

    .days-count-green {
        color: green;
    }

    .App-link {
        color: #61dafb;
    }

    @keyframes App-logo-spin {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }
